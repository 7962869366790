var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Нормативні параметри ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'LegacyNormative', title: 'Нормативні параметри' },"button":{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' },"item":{
                    flat_name: _vm.flat.full_address,
                    person_hash: _vm.flat.person_hash,
                  }},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.houseParameterHeaders,"items":_vm.indicator_items,"hide-default-footer":""},on:{"click:row":_vm.openFlatIndicatorModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'LegacyNormative', title: 'Нормативні параметри' },"button":{ iconActivator: true, class: 'mr-2', color: 'success', buttonIcon: 'mdi-format-list-numbered', iconSize: 26 },"item":{
                    id: item.id,
                    flat_name: _vm.flat.full_address,
                    person_hash: _vm.flat.person_hash,
                    indicator_id: item.indicator_id,
                    contract_id: item.contract_id,
                    name: item.unit_type_name,
                    value_dec: item.value_dec,
                    date_start: item.date_start,
                    date_end: item.date_end,
                    actual: _vm.actual
                  },"modal":_vm.flat_indicator_dialog_id === item.id},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})]}},{key:"item.date_start",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}},{key:"item.date_end",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_end))+" ")])]}},{key:"item.value_dec",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.value_dec))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }