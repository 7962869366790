<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Нормативні параметри
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            :main="{ component: 'LegacyNormative', title: 'Нормативні параметри' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{
                      flat_name: flat.full_address,
                      person_hash: flat.person_hash,
                    }"
            @updateItemModal="flatIndicatorUpdateItemModal"
        ></MainModal>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="indicator_items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openFlatIndicatorModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'LegacyNormative', title: 'Нормативні параметри' }"
            :button="{ iconActivator: true, class: 'mr-2', color: 'success', buttonIcon: 'mdi-format-list-numbered', iconSize: 26 }"
            :item="{
                      id: item.id,
                      flat_name: flat.full_address,
                      person_hash: flat.person_hash,
                      indicator_id: item.indicator_id,
                      contract_id: item.contract_id,
                      name: item.unit_type_name,
                      value_dec: item.value_dec,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      actual: actual
                    }"
            :modal="flat_indicator_dialog_id === item.id"
            @updateItemModal="flatIndicatorUpdateItemModal"
        />
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.value_dec="{ item }">
        <span>
          {{ item.value_dec | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {
  FETCH_FLAT_INDICATOR_TYPES,
  FETCH_LEGACY_FLAT_INDICATORS,
} from "@/store/actions/flat_indicators";
export default {
  name: "HWL_Normatives",
  props: {
    actual: {
      type: Boolean,
      default: false
    }
  },
  components: { MainModal },
  data() {
    return {
      flat_indicator_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Договір', value: 'contract_name' },
        { text: 'Назва параметра', value: 'indicator_name' },
        { text: 'Одн.вим', value: 'unit_type_name' },
        { text: 'Значення', value: 'value_dec' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      indicators: 'getFLatIndicators',
      flat: 'getLegacyFlat'
    }),
    indicator_items() {
      if (this.actual) {
        return this.indicators.filter(item => item.actual)
      } else {
        return this.indicators
      }
    }
  },
  methods: {
    ...mapActions({
      fetchIndicators: FETCH_LEGACY_FLAT_INDICATORS,
      fetchIndicatorTypes: FETCH_FLAT_INDICATOR_TYPES
    }),
    flatIndicatorUpdateItemModal(payload) {
      this.flat_indicator_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openFlatIndicatorModal(row) {
      this.flat_indicator_dialog_id = row.id
    }
  },
  created() {
    this.fetchIndicators(this.$route.params.person_hash)
        .then(res => {
          if (res) {
            this.fetchIndicatorTypes()
          }
        })
  }
}
</script>

<style scoped lang="scss">

</style>